import React from 'react';
import type * as yup from 'yup';
import type {
  CompDefinition,
  DefaultCompPlatformProps,
} from '@wix/editor-elements-types/thunderbolt';
import { LoginSocialBarSdkData } from '@wix/thunderbolt-components';
import {
  LinkProps,
  ResolvedCustomMenu,
} from '@wix/thunderbolt-components-native';
import type { CorvidTypes } from '@wix/editor-elements-types/corvid';
import type {
  IElementPropsSDK,
  IElementPropsSDKActions,
} from '@wix/editor-elements-corvid-utils';
import { PickStateRefValues } from '@wix/editor-elements-integrations';
import type { Overwrite } from '@wix/editor-elements-types/utils';
import { MapperReportClickProps } from '@wix/editor-elements-common-utils';
import { ViewerTranslations } from '@wix/editor-elements-types/src/components';
import {
  dataSchema,
  propertiesSchema,
} from './documentManagement/LoginSocialBar.schemaTypes';

export enum AvatarState {
  Initials,
  Image,
  Vector,
}

export enum Layout {
  AvatarOnly = 'avatarOnly',
  AvatarAndText = 'avatarAndText',
  TextOnly = 'textOnly',
  MemberInitials = 'memberInitials',
}

export enum ArrowShape {
  None = 'none',
  Line = 'line',
  Full = 'full',
}

export enum Alignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface ILoginSocialBarCustomMenu {
  focusFirstMenuItem(): void;
}

export type LoginSocialBarUnresolvedData = yup.InferType<typeof dataSchema>;
export type LoginSocialBarData = Overwrite<
  LoginSocialBarUnresolvedData,
  {
    menuItemsRef: { menuRef: ResolvedCustomMenu };
    iconItemsRef: { menuRef: ResolvedCustomMenu };
  }
>;
export type LoginSocialBarProperties = yup.InferType<typeof propertiesSchema>;
export type LoginSocialBarSkin = 'LoginSocialBarSkin';

export type LoginSocialBarDefinition = CompDefinition<
  LoginSocialBarSkin,
  LoginSocialBarData,
  LoginSocialBarProperties,
  never,
  LoginSocialBarUnresolvedData
>;

export type LoginSocialBarCSSVars = {
  'justify-content': CSSStyleDeclaration['justifyContent'];
  direction: CSSStyleDeclaration['direction'];
  '--icon-size': string;
  '--fnt': string;
};

export type AvatarWrapperProps = Pick<
  ILoginSocialBarProps,
  | 'id'
  | 'isLoggedIn'
  | 'avatarUri'
  | 'iconSize'
  | 'defaultAvatarSvgContent'
  | 'userName'
  | 'displayMode'
>;

export type LoginSocialBarMenuItemProps = {
  label: string;
  link: LinkProps;
  displayCount?: number;
};

export type LoginSocialBarIconItemProps = LoginSocialBarMenuItemProps & {
  iconSvgContent: string;
};

export type LoginSocialBarSiteAssetProps = {
  /**
   * Skin name.
   */
  skin: LoginSocialBarDefinition['skin'];
  /**
   * Display mode for login bar.
   */
  displayMode: LoginSocialBarData['loggedInMember'];
  /**
   * Text for login message.
   */
  loggedOutText: LoginSocialBarData['loggedOutText'];
  /**
   * Text for logout message.
   */
  logOutText: LoginSocialBarData['logOutText'];
  /**
   * Text to display before user name/avatar (a greeting).
   */
  loggedInText: LoginSocialBarData['loggedInText'];
  /**
   * Size of avatar and social button icons.
   */
  iconSize: number;
  /**
   * Arrangement of icons.
   */
  direction: LoginSocialBarProperties['buttonsDirection'];
  /**
   * Use native instead of custom HTML menu.
   */
  useNativeMenu: boolean;
  /**
   * Menu items for dropdown menu.
   */
  menuItems: Array<LoginSocialBarMenuItemProps>;
  /**
   * Icon items to be displayed on login bar.
   */
  iconItems: Array<LoginSocialBarIconItemProps>;
  /**
   * Default avatar SVG content.
   */
  defaultAvatarSvgContent: string;
  /**
   * Arrow size of login bar.
   */
  arrowSize: LoginSocialBarProperties['arrowSize'];
  /**
   * Arrow shape of login bar.
   */
  arrowShape: LoginSocialBarProperties['arrowShape'];
  /**
   * Should show bell icon.
   */
  showBellIcon: LoginSocialBarProperties['showBellIcon'];
  /**
   * Alignment of menu items.
   */
  menuItemsAlignment: LoginSocialBarProperties['dropDownTextAlignment'];
};

export type LoginSocialBarFeatureProps = {
  /**
   * The href of the current primary page.
   */
  currentPrimaryPageHref: string;
  /**
   * Whether the user is logged in or not.
   */
  isLoggedIn: boolean;
  /**
   * Logged in user name.
   */
  userName: string;
  /**
   * Avatar image URI for logged in user.
   */
  avatarUri: string;
  /**
   * Event used to trigger login action.
   */
  onLogin(): void;
  /**
   * Event used to trigger logout action.
   */
  onLogout(event?: React.MouseEvent): void;
};

export type ILoginSocialBarMapperProps = LoginSocialBarSiteAssetProps &
  LoginSocialBarFeatureProps &
  Omit<MapperReportClickProps, 'pagesMap'> &
  Pick<DefaultCompPlatformProps, 'fullNameCompType'>;

export type ILoginSocialBarStateValues = PickStateRefValues<
  | 'memberDetails'
  | 'reportBi'
  | 'currentUrl'
  | 'navigateTo'
  | 'logout'
  | 'promptLogin'
>;

export type ILoginSocialBarControllerProps = Omit<
  ILoginSocialBarMapperProps,
  keyof MapperReportClickProps
> &
  Pick<ILoginSocialBarStateValues, 'navigateTo' | 'reportBi'> & {
    onMenuOpen(): void;
    onMenuClose(): void;
    reportBiOnClick: (label: string) => void;
    reportBiOnMenuItemClick: (item: LoginSocialBarMenuItemProps) => void;
  };

export type ILoginSocialBarPreviewMapperProps = ILoginSocialBarMapperProps & {
  isMenuOpen: boolean;
};

export type ILoginSocialBarProps = DefaultCompPlatformProps &
  ILoginSocialBarControllerProps &
  Partial<IElementPropsSDKActions> & {
    isMenuOpen: boolean;
    className?: string;
  } & ViewerTranslations<
    'loggedOutText' | 'logOutText' | 'menuOptionValueText'
  >;

type ILoginSocialBarSDKDataMenuItem = LoginSocialBarSdkData['menuItems'][0];
export type ILoginSocialBarSDKMenuItem =
  Partial<ILoginSocialBarSDKDataMenuItem> &
    Pick<ILoginSocialBarSDKDataMenuItem, 'label'>;

export type ILoginSocialBarSDKMenuItems = Array<ILoginSocialBarSDKMenuItem>;

type ILoginSocialBarSDKDataNavBarItem = LoginSocialBarSdkData['navBarItems'][0];
export type ILoginSocialBarSDKNavBarItem =
  Partial<ILoginSocialBarSDKDataNavBarItem> &
    Pick<ILoginSocialBarSDKDataNavBarItem, 'icon'>;

export type ILoginSocialBarSDKNavBarItems = Array<ILoginSocialBarSDKNavBarItem>;

export type ILoginSocialBarOwnSDK = {
  menuItems: ILoginSocialBarSDKMenuItems;
  navBarItems: ILoginSocialBarSDKNavBarItems;
};

export type ILoginSocialBarSDK = ILoginSocialBarOwnSDK & IElementPropsSDK;

export type ILoginSocialBarOwnSDKFactory = CorvidTypes.CorvidSDKFactory<
  ILoginSocialBarProps,
  ILoginSocialBarOwnSDK,
  LoginSocialBarSdkData
>;

export type ILoginSocialBarSDKFactory = CorvidTypes.CorvidSDKFactory<
  ILoginSocialBarProps,
  ILoginSocialBarSDK,
  LoginSocialBarSdkData
>;

export type ILoginSocialBarCorvidModel =
  CorvidTypes.ICorvidModel<ILoginSocialBarSDKFactory>;
